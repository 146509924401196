import { useMutation, useQuery } from "@tanstack/react-query";
import React from "react";
import { useState, useEffect } from "react";import Banner from '../components/Banner'
import Categories from '../components/Categories'
import WebsiteLayout from '../layouts/WebsiteLayout'
import { getCategoriesById, getMenus, getProductsId } from "../api/products.api";
import { displayAmount, makeTitleCase, queryError, showAlert } from "../utils/helpers";
import { NAIRA_SIGN } from "../configs/app";
import SingleProduct from "../components/SingleProduct";

const Menu = () => {
  const [menus, setMenus] = useState([])
  const [currentMenu, setCurrentMenu] = useState(null)
  const [categories, setCategories] = useState(null)
  const [currentCategory, setCurrentCategory] = useState(null)
  const [products, setProducts] = useState(null)


  const { data } = useQuery(['menus'], getMenus , {
    onSuccess: data => {
        if(!data.status)return showAlert({
            title: "Oops!",
            msg: typeof data.errors[0].msg == "string"
            ? data.errors[0].msg
            : data.errors[0].msg.message,
            icon: "error",
            btnText: "Close" 
          });
          setMenus(data?.value)
          setCurrentMenu(data?.value[0])
    },
    onError: err => queryError()

})

const {data:menuCategory, refetch:getMenuCategory} = useQuery(['categories', currentMenu?._id], getCategoriesById ,{
enabled:false,
onSuccess: data => {
    if(!data.status)return showAlert({
        title: "Oops!",
        msg: typeof data.errors[0].msg == "string"
        ? data.errors[0].msg
        : data.errors[0].msg.message,
        icon: "error",
        btnText: "Close" 
      });
      setCategories(data?.value)
      setCurrentCategory(data?.value[0])
},
onError: err => queryError()
})

const {mutate:getProducts} = useMutation(getProductsId,{
onSuccess: data => {
    if(!data.status)return showAlert({
        title: "Oops!",
        msg: typeof data.errors[0].msg == "string"
        ? data.errors[0].msg
        : data.errors[0].msg.message,
        icon: "error",
        btnText: "Close" 
      });
   setProducts(data?.value)
},
onError: err => queryError()
})

useEffect(() => {
if(currentCategory){
getProducts({key:"catid",val:currentCategory?._id})
}
}, [currentCategory])


useEffect(() => {
if(currentMenu){
if(currentMenu?.hasCategory){
getMenuCategory()
}else{
//get products with menuid
getProducts({key:"menuid", val:currentMenu?._id })
}
}
}, [currentMenu])


  return (
    <WebsiteLayout>
       <Banner title="our menu" link="Menu"/>
        {/* <Categories/> */}

        <section className="sb-menu-section sb-p-150-60">
        <div className="sb-bg-1">
          <div />
        </div>

        <div className="container">

        <div className={`sb-filter ${currentMenu?.hasCategory?"mb-3":"mb-30"}`} style={{textAlign:"center", display:"flex", overflow:"scroll", overflowY:"hidden"}}>
            { menus.map(menu=>
            <button 
            key={Math.floor(Math.random()*999999)+"hehj"}  
            className={`sb-filter-link ${currentMenu?._id === menu._id?"sb-active":""}`}
            style={{color:currentMenu?._id === menu._id?"#fff":"#000",height:"unset", padding:20, border:"none", display:"inline", marginRight:10, marginBottom:10}}
            onClick={()=>setCurrentMenu(menu)} 
            >
                <i className={`fa ${menu.icon}`}></i>{" "}
                <span>{makeTitleCase(menu.name)}</span>
            </button>)
            }
        </div>

        { currentMenu?.hasCategory && categories &&  <div className={`sb-filter mb-30}`} style={{textAlign:"center", display:"flex", overflow:"scroll", overflowY:"hidden"}}>
            { categories?.map(category=>
            <button 
            key={Math.floor(Math.random()*99999)+"catdhd"}  
            className={`sb-filter-link ${currentCategory?._id === category._id?"sb-active":""}`}
            style={{color:currentCategory?._id === category._id?"#fff":"#000",height:"unset", padding:20, border:"none", display:"inline", marginRight:10, marginBottom:10}}
            onClick={()=>setCurrentCategory(category)} 
            >
                <i className={`fa ${category.icon}`}></i>{" "}
                <span>{makeTitleCase(category.name)}</span>
            </button>)
            }
        </div>
        }


{currentMenu?.title && <div className="mb-5" style={{textAlign:"center"}}>
  <p>{currentMenu?.title}</p>
</div>}
{currentCategory?.title && <div className="mb-5" style={{textAlign:"center"}}>
  <p>{currentCategory?.title}</p>
</div>}




          <div className="sb-masonry-grid">
          <div className="sb-grid-sizer" />
          {
    products && products.length > 0 && products.map(
        product =><SingleProduct key={Math.floor(Math.random() * 99999)+"ndc"} prod={product} />
        )
        }



          </div>


          </div>
        </section>
    </WebsiteLayout>
  )
}

export default Menu