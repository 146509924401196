import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useState, useEffect } from "react";
import { Button, Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import { updateProductWithData } from "../api/products.api";
import { queryError, showAlert } from "../utils/helpers";

const EditProductModal = ({ show, close, product, menu, categories }) => {
  const { _id, name, price, description, menuid, categoryid, active , image} = product;
  const initialState = {
    id:_id,
    name,
    price,
    description,
    menuid,
    categoryid,
    active,
    image
  };
  const emptyState = {
    id:null,
    name: "",
    price: 0,
    description: "",
    menuid: null,
    categoryid: null,
    active: false,
    image:null
  };
  const [formdata, setFormdata] = useState(initialState);

  const handleChange = (field, value) => {
    if (field === "active") {
      return setFormdata((prevState) => ({
        ...prevState,
        [field]: value.checked,
      }));
    }
    setFormdata((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const {mutate:updateProduct, isLoading:isUpdating} = useMutation(updateProductWithData,{
    onSuccess: data => {
      if(!data.status)return showAlert({
          title: "Oops!",
          msg: typeof data.errors[0].msg == "string"
          ? data.errors[0].msg
          : data.errors[0].msg.message,
          icon: "error",
          btnText: "Close" 
        });
        close()
        setFormdata(initialState)
        return showAlert({
          title: "Success",
          msg: "Product updated successfully",
          icon: "success",
          btnText: "Close" 
        });
  },
  onError: err => queryError()
  })

  const handleSubmit = async (e) => {
    e.preventDefault();
    updateProduct(formdata)
}
  return (
    <Modal
      show={show}
      onHide={close}
      backdrop="static"
      keyboard={false}
      size={"md"}
    >
      <Modal.Header closeButton>
        <Modal.Title>Update Product</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter a name for the Product"
              onChange={(e) => handleChange("name", e.target.value)}
              value={formdata.name}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formDescription">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="a short description about the product"
              onChange={(e) => handleChange("description", e.target.value)}
              value={formdata.description}
            />
          </Form.Group>

          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formSelect">
                <Form.Label>Category</Form.Label>
                <Form.Select
                  aria-label="Category"
                  className="form-control"
                  onChange={(e) => handleChange("menuid", e.target.value)}
                  value={formdata.menuid}
                >
                  <option disabled selected value={null}>Select Category </option>
                  {
                    menu?.map(item=><option value={item._id} key={Math.floor(Math.random()*999999)+"jdb"}>{item.name}</option>)
                  }
                </Form.Select>
              </Form.Group>
            </Col>
            { categories &&
            <Col>
              <Form.Group className="mb-3" controlId="formSelect">
                <Form.Label>Sub-category</Form.Label>
                <Form.Select
                  aria-label="Sub Category"
                  className="form-control"
                  onChange={(e) => handleChange("categoryid", e.target.value)}
                  value={formdata.categoryid}
                >
                  <option disabled selected value={null}>Select Sub-category</option>
                  {
                    categories?.map(item=><option value={item._id} key={Math.floor(Math.random()*999999)+"jdb"}>{item.name}</option>)
                  }
                </Form.Select>
              </Form.Group>
            </Col>
}
          </Row>

          <Row>
            <Col>
            <Form.Group className="mb-3" controlId="formPrice">
                <Form.Label>Image link</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Paste a link to your image"
                  onChange={(e) => handleChange("image", e.target.value)}
                  value={formdata.image}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formPrice">
                <Form.Label>Price</Form.Label>
                <Form.Control
                  type="number"
                  placeholder=""
                  onChange={(e) => handleChange("price", e.target.value)}
                  value={formdata.price}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col>
              <Form.Check
                type="switch"
                id="available-checkbox"
                label="Available on sale?"
                checked={formdata.active}
                onChange={(e) => handleChange("active", e.target)}
              />
              <Form.Text className="text-muted">
                <small>
                  Switch ON to make this product visible or OFF to hide this
                  product
                </small>
              </Form.Text>
            </Col>
          </Row>

          <Row></Row>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={close}>
          Close
        </Button>
        <Button
          onClick={handleSubmit}
          type="submit"
          variant="primary"
          disabled={isUpdating}
        >
          {isUpdating ? (
            <>
              <Spinner animation="border" size="sm" color="#fff" />{" "}
              Updating...Plese Wait"
            </>
          ) : (
            "Update Product"
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditProductModal;
