import React from 'react'

const AboutPageSection = () => {
  return (
    <section className="sb-about-text sb-p-90-0">
  <div className="sb-bg-1" style={{marginTop: 90}}>
    <div />
  </div>
  <div className="container">
    <div className="row">
      <div className="col-lg-6 align-self-center">
        <div className="sb-illustration-2 sb-mb-90">
          <div className="sb-interior-frame">
            <img src="img/gallery/23.jpeg" alt="interior" className="sb-interior" style={{objectPosition: 'center'}} />
          </div>
          <div className="sb-square" />
          <div className="sb-cirkle-1" />
          <div className="sb-cirkle-2" />
          <div className="sb-cirkle-3" />
          <div className="sb-cirkle-4" />
          {/* <div className="sb-experience">
            <div className="sb-exp-content">
              <p className="sb-h1 sb-mb-10">9</p>
              <p className="sb-h3">Years in Service</p>
            </div>
          </div> */}
        </div>
      </div>
      <div className="col-lg-6 align-self-center sb-mb-60">
        <h2 className="sb-mb-60">True Gem Of Port Harcourt's Skyline</h2>
        <p className="sb-text sb-mb-30">
        Sky Bar is Best Rooftop Luxury Bar with Widest Variety of Special Craft Cocktails, Un-Ending Wines & Drinks List for all drinks aficionados. We serve International as well as Local delicacies with focus on freshest & Best Ingredients, Great taste & Fine Dining. 
        </p>
        <p className="sb-text sb-mb-60">
Sky Bar has different seating options for all sort of Customers spread across VIP Room, Exclusive Comfy Cushioned Lounge, Terrace area, Bar Area. Minimalist Ambience, High-End Furnishing, Live Djs, Special Events with more Focus on Exceptional Experience, Fine Dining makes us stand out from the rest.</p>
<p>We Create Great Memories, We serve luxury Re-Defined! We Are Sky Bar!</p>
        <img src="img/ui/signature.png" alt="Signature" className="sb-signature sb-mb-30" />
      </div>
    </div>
  </div>
</section>

  )
}

export default AboutPageSection