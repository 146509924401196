import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {

  const handleRedirect = (loc) => {
      let address = loc === "instagram"?"https://www.instagram.com/skybarphc/":loc === "facebook"?"https://www.facebook.com/profile.php?id=100083162058939":"";

      window.location = address
  }
  return (
    <footer>
      <div className="container">
        <div className="sb-footer-frame">
          <Link to="/" className="sb-logo-frame">
            {/* logo img */}
            <img  style={{width:70}} src={require("../assets/images/logo.png")} alt="Skybar Logo"  />
          </Link>
          <ul className="sb-social">
            {/* <li>
              <a href="#.">
                <i className="fab fa-twitter" />
              </a>
            </li> */}
            <li>
              {/* <a href="https://www.instagram.com/skybarphc/" onClick={(e)=>e.preventDefault()}> */}
                <i className="fab fa-instagram" style={{cursor:"pointer"}} onClick={()=>handleRedirect("instagram")} />
              {/* </a> */}
            </li>
            <li>
              {/* <a target="_blank" href="https://www.facebook.com/profile.php?id=100083162058939"> */}
                <i className="fab fa-facebook-f" style={{cursor:"pointer"}}  onClick={()=>handleRedirect("facebook")} />
              {/* </a> */}
            </li>
            {/* <li>
              <a href="#.">
                <i className="fab fa-youtube" />
              </a>
            </li> */}
          </ul>
          <div className="sb-copy">
            © {new Date().getFullYear()} Genesis Skybar. All Rights Reserved.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
