
import axios from 'axios'
import { BASE_API_URL } from '../configs/api'

export const getMenus = async () => {
    const resp = await axios.get(`${BASE_API_URL}/admin/get-menus`)
    return resp.data
}
export const getCategoriesById = async ({queryKey}) => {
    // console.log(queryKey)
    const resp = await axios.get(`${BASE_API_URL}/admin/get-menu-category/${queryKey[1]}`)
    return resp.data
}
export const getProductsId = async (data) => {
    const resp = await axios.post(`${BASE_API_URL}/admin/get-products-by-category`, data)
    return resp.data
}
export const deleteProductsId = async (data) => {
    const resp = await axios.post(`${BASE_API_URL}/admin/delete-products-by-id`, data)
    return resp.data
}
export const updateProductWithData = async (data) => {
    const resp = await axios.post(`${BASE_API_URL}/admin/update-product`, data)
    return resp.data
}
export const createProductWithData = async (data) => {
    const resp = await axios.post(`${BASE_API_URL}/admin/create-product`, data)
    return resp.data
}

