import React from 'react'

const AboutSection = () => {
  return (
    <section className="sb-about-text sb-p-90-0">
  <div className="sb-bg-1" style={{marginTop: 90}}>
    <div />
  </div>
  <div className="container">
    <div className="row">
      <div className="col-lg-6 align-self-center">
        <div className="sb-illustration-2 sb-mb-90">
          <div className="sb-interior-frame">
            <img src="img/gallery/2.jpg" alt="interior" className="sb-interior" style={{objectPosition: 'center'}} />
          </div>
          <div className="sb-square" />
          <div className="sb-cirkle-1" />
          <div className="sb-cirkle-2" />
          <div className="sb-cirkle-3" />
          <div className="sb-cirkle-4" />
          <div className="sb-experience">
            <div className="sb-exp-content">
              <p className="sb-h1 sb-mb-10">9</p>
              <p className="sb-h3">Years in Service</p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-6 align-self-center sb-mb-60">
        <h2 className="sb-mb-60">Luxury meets rooftop dining perfection.</h2>
        <p className="sb-text sb-mb-30">At Sky Bar, we bring you an unparalleled dining experience that combines luxury and the breathtaking beauty of a rooftop setting. Perched high above the city, our establishment offers a truly enchanting ambiance that will captivate your senses from the moment you step in.</p>
        <p className="sb-text sb-mb-60">We believe that luxury is not just about the setting, but also about the experience. That's why we go above and beyond to provide exceptional service that caters to your every need. Our attentive staff is dedicated to ensuring that your visit is nothing short of extraordinary, leaving you with memories that will last a lifetime.</p>
        <img src="img/ui/signature.png" alt="Signature" className="sb-signature sb-mb-30" />
      </div>
    </div>
  </div>
</section>

  )
}

export default AboutSection