import React from 'react'

const BenefitsSection = () => {
  return (
   <section className="sb-features sb-p-0-30">
  <div className="container">
    <div className="row">
      <div className="col-lg-4">
        <div className="sb-features-item sb-mb-60">
          <div className="sb-number">01</div>
          <div className="sb-feature-text">
            <h3 className="sb-mb-15">Breathtaking Rooftop Views</h3>
            <p className="sb-text">Whether you're enjoying a romantic dinner or celebrating a special occasion, the breathtaking views add a touch of magic to your evening.</p>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="sb-features-item sb-mb-60">
          <div className="sb-number">02</div>
          <div className="sb-feature-text">
            <h3 className="sb-mb-15">Luxurious Ambiance</h3>
            <p className="sb-text">From the high-end furnishings to the carefully curated decor, every detail is designed to create an atmosphere of refinement.</p>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="sb-features-item sb-mb-60">
          <div className="sb-number">03</div>
          <div className="sb-feature-text">
            <h3 className="sb-mb-15">Wide Variety of Craft Cocktails</h3>
            <p className="sb-text">From classic favorites to innovative creations, each drink is a work of art, made with premium ingredients and meticulous attention to detail.</p>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="sb-features-item sb-mb-60">
          <div className="sb-number">04</div>
          <div className="sb-feature-text">
            <h3 className="sb-mb-15">Fine Wine Selection</h3>
            <p className="sb-text">Carefully curated from renowned vineyards around the world, the wine menu offers a variety of options to suit different preferences and occasions</p>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="sb-features-item sb-mb-60">
          <div className="sb-number">05</div>
          <div className="sb-feature-text">
            <h3 className="sb-mb-15">Exceptional Service</h3>
            <p className="sb-text">From the moment you arrive, our attentive and professional staff will cater to your needs, ensuring that every aspect of your visit is seamless and enjoyable.</p>
          </div>
        </div>
      </div>
      <div className="col-lg-4">
        <div className="sb-features-item sb-mb-60">
          <div className="sb-number">06</div>
          <div className="sb-feature-text">
            <h3 className="sb-mb-15">Special Events and Live Entertainment</h3>
            <p className="sb-text">Sky Bar goes beyond being a dining destination by offering special events and live entertainment.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

  )
}

export default BenefitsSection