import React from 'react'
import './App.css'
import Home from './screens/Home'
import Menu from './screens/Menu'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from './screens/Login';
import Admin from './screens/Admin';
import Logout from './screens/Logout';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import ProtectedRoute from './components/ProtectedRoute';
import About from './screens/About';
import Gallery from './screens/Gallery';

const queryClient = new QueryClient()

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
    <Router>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/menu" element={<Menu />} />
      <Route path="/about" element={<About />} />
      <Route path="/gallery" element={<Gallery />} />
      <Route path="/login" element={<Login/>} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/admin" element={<ProtectedRoute><Admin /></ProtectedRoute>} />



      <Route path="/*" element={<Menu />} />

    </Routes>
    </Router>
    </QueryClientProvider>
  )
}

export default App