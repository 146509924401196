import React from 'react'
import { Link } from 'react-router-dom'

const DeliverySection = () => {
    const handleRedirect = (e) => {
        e.preventDefault()
        console.log("Here")
        window.location = "https://wa.me/2349087610221?text=I%want%to%20order%20for%20food"
    }
  return (
   <section className="sb-call-to-action">
  <div className="sb-bg-3" />
  <div className="container">
    <div className="row">
      <div className="col-lg-6 align-self-center">
        <div className="sb-cta-text">
          <h2 className="sb-h1 sb-mb-15">Delivery service.</h2>
          <p className="sb-text sb-mb-30">Place an order and<br />we'll have it delivered to you.</p>
          {/* button */}
          <a target="_blank" onClick={handleRedirect} href="#" className="sb-btn">
            <span className="sb-icon">
              <img src="img/ui/icons/cart.svg" alt="icon" />
            </span>
            <span>Order delivery</span>
          </a>
          {/* button end */}
          {/* button */}
          <Link to="/menu" className="sb-btn sb-btn-2 sb-btn-gray">
            <span className="sb-icon">
              <img src="img/ui/icons/menu.svg" alt="icon" />
            </span>
            <span>Menu</span>
          </Link>
          {/* button end */}
        </div>
      </div>
      <div className="col-lg-6">
        <div className="sb-illustration-8">
          <img src="img/illustrations/sky_delivery.png" alt="reserved" className="sb-reserved" style={{width:"50%",height:"auto", right:50}} />
          <div className="sb-cirkle-1" />
          <div className="sb-cirkle-2" />
          <div className="sb-cirkle-3" />
          <div className="sb-cirkle-4" />
          <div className="sb-cirkle-5" />
          <img src="img/illustrations/2.svg" alt="icon" className="sb-pik-2" />
          <img src="img/illustrations/3.svg" alt="icon" className="sb-pik-3" />
        </div>
      </div>
    </div>
  </div>
</section>

  )
}

export default DeliverySection