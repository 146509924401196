import React from "react";
import AboutPageSection from "../components/AboutPageSection";
import AboutSection from "../components/AboutSection";
import Banner from "../components/Banner";
import BenefitsSection from "../components/BenefitsSection";
import PromoVideo from "../components/PromoVideo";
import WebsiteLayout from "../layouts/WebsiteLayout";
import { InstagramEmbed } from "react-social-media-embed";

let banner = require("../assets/images/about_banner.webp");
const About = () => {
  return (
    <WebsiteLayout>
      <Banner title="About Us" link="About" bannerImage={banner} />
      <AboutSection />
      <BenefitsSection />
      <PromoVideo />
     
    </WebsiteLayout>
  );
};

export default About;
