import { useMutation, useQuery } from "@tanstack/react-query";
import React from "react";
import { useState, useEffect } from "react";
import { deleteProductsId, getCategoriesById, getMenus, getProductsId } from "../api/products.api";
import { NAIRA_SIGN } from "../configs/app";
import WebsiteLayout from "../layouts/WebsiteLayout";
import CreateProductModal from "../modal/CreateProductModal";
import EditProductModal from "../modal/EditProductModal";
import { displayAmount, makeTitleCase, queryError, showAlert } from "../utils/helpers";

const Admin = () => {
    const [menus, setMenus] = useState([])
    const [currentMenu, setCurrentMenu] = useState(null)
    const [categories, setCategories] = useState(null)
    const [currentCategory, setCurrentCategory] = useState(null)
    const [products, setProducts] = useState(null)
    const [selectedProduct, setSelectedProduct] = useState(null)
    const [showEditModal, setShowEditModal] = useState(false)
    const [showCreateModal, setShowCreateModal] = useState(false)

    const { data } = useQuery(['menus'], getMenus , {
        onSuccess: data => {
            if(!data.status)return showAlert({
                title: "Oops!",
                msg: typeof data.errors[0].msg == "string"
                ? data.errors[0].msg
                : data.errors[0].msg.message,
                icon: "error",
                btnText: "Close" 
              });
              setMenus(data?.value)
              setCurrentMenu(data?.value[0])
        },
        onError: err => queryError()

    })

const {data:menuCategory, refetch:getMenuCategory} = useQuery(['categories', currentMenu?._id], getCategoriesById ,{
    enabled:false,
    onSuccess: data => {
        if(!data.status)return showAlert({
            title: "Oops!",
            msg: typeof data.errors[0].msg == "string"
            ? data.errors[0].msg
            : data.errors[0].msg.message,
            icon: "error",
            btnText: "Close" 
          });
          setCategories(data?.value)
          setCurrentCategory(data?.value[0])
    },
    onError: err => queryError()
})

const {mutate:getProducts} = useMutation(getProductsId,{
    onSuccess: data => {
        if(!data.status)return showAlert({
            title: "Oops!",
            msg: typeof data.errors[0].msg == "string"
            ? data.errors[0].msg
            : data.errors[0].msg.message,
            icon: "error",
            btnText: "Close" 
          });
       setProducts(data?.value)
    },
    onError: err => queryError()
})

useEffect(() => {
  if(currentCategory){
    getProducts({key:"catid",val:currentCategory?._id,isAdmin:true})
  }
}, [currentCategory])


useEffect(() => {
    if(currentMenu){
 if(currentMenu?.hasCategory){
    getMenuCategory()
 }else{
    //get products with menuid
    getProducts({key:"menuid", val:currentMenu?._id,isAdmin:true })
 }
 }
}, [currentMenu])

const {mutate:deleteProd} = useMutation(deleteProductsId,{
    onSuccess: data => {
        if(!data.status)return showAlert({
            title: "Oops!",
            msg: typeof data.errors[0].msg == "string"
            ? data.errors[0].msg
            : data.errors[0].msg.message,
            icon: "error",
            btnText: "Close" 
          });
           showAlert({
            title: "Success",
            msg: "Product deleted successfully",
            icon: "success",
            btnText: "Close" 
          });
          return true
         
          
    },
    onError: err => queryError()
})
const handleDelete = async (id) => {
    let conf = window.confirm("Are you sure you want to delete");
    if(!conf) return;
   let res =  await deleteProd({id})
   if(res){
    let newProds = products.filter(item=>item._id !== id)
    setProducts(newProds)
   }
}

const handleEdit = (item) => {
    setSelectedProduct(item);
    setShowEditModal(true)
  }


  const handleClose = (type) => {
    currentCategory?getProducts({key:"catid",val:currentCategory?._id,isAdmin:true}):getProducts({key:"menuid", val:currentMenu?._id,isAdmin:true })
    switch(type){
      case "create":setShowCreateModal(false);  
      break;
      case "edit":setShowEditModal(false); setSelectedProduct(null);  
      break;
      default:return;
    }
  }



  return (
    <WebsiteLayout isAdmin={true} createNewProduct={()=>setShowCreateModal(true)}>
      <section className="sb-menu-section sb-p-150-60">
        <div className="sb-bg-1">
          <div />
        </div>
        <div className="container">
        
        <div className={`sb-filter ${currentMenu?.hasCategory?"mb-3":"mb-30"}`} style={{textAlign:"center", display:"flex", overflow:"scroll", overflowY:"hidden"}}>
            { menus.map(menu=>
            <button 
            key={Math.floor(Math.random()*999999)+"hehj"}  
            className={`sb-filter-link ${currentMenu?._id === menu._id?"sb-active":""}`}
            style={{color:currentMenu?._id === menu._id?"#fff":"#000",height:"unset", padding:20, border:"none", display:"inline", marginRight:10, marginBottom:10}}
            onClick={()=>setCurrentMenu(menu)} 
            >
                <i className={`fa ${menu.icon}`}></i>{" "}
                <span>{makeTitleCase(menu.name)}</span>
            </button>)
            }
        </div>

        { currentMenu?.hasCategory && categories &&  <div className={`sb-filter mb-30}`} style={{textAlign:"center", display:"flex", overflow:"scroll", overflowY:"hidden"}}>
            { categories?.map(category=>
            <button 
            key={Math.floor(Math.random()*99999)+"catdhd"}  
            className={`sb-filter-link ${currentCategory?._id === category._id?"sb-active":""}`}
            style={{color:currentCategory?._id === category._id?"#fff":"#000",height:"unset", padding:20, border:"none", display:"inline", marginRight:10, marginBottom:10}}
            onClick={()=>setCurrentCategory(category)} 
            >
                <i className={`fa ${category.icon}`}></i>{" "}
                <span>{makeTitleCase(category.name)}</span>
            </button>)
            }
        </div>

        }

          
          {/* filter end */}
          <div className="sb-masonry-grid">
            <div className="sb-grid-sizer" />
{
    products && products.length > 0 && products.map(
        product =>{
            let amount = typeof product.price === "number" ?<><sub>{NAIRA_SIGN}</sub>{displayAmount(product.price)}</>:product.price
        return (
        <div key={Math.floor(Math.random() * 999999)+"ien"} className="sb-grid-item sb-item-50 main-dishes">
        <div className="sb-menu-item sb-mb-30">
          <div className="sb-card-tp">
            <h4 className="sb-card-title">{makeTitleCase(product.name)}</h4>
            <div className="sb-price">
             {amount}
            </div>
          </div>
          <div className="sb-description">
            <p className="sb-text sb-mb-15">
             {product.description}
            </p>
            <ul className="sb-stars" style={{float:"right"}}>
              <li className="mr-3">
                <button className="btn btn-sm btn-danger" onClick={()=>handleDelete(product._id)}>
                    <i className="fa fa-trash-alt"></i>
                    <span className=" text-white"> Delete </span>
                </button>
              </li>
              <li>
                <button onClick={()=>handleEdit(product)}  className="btn btn-sm btn-warning" style={{backgroundColor:"#d1a965", border:"1px solid #d1a965"}}>
                    <i className="fa fa-pencil-alt text-white"></i>
                    <span className=" text-white"> Edit </span>
                </button>
              </li>
            </ul>
            <div className="clearfix"></div>
          </div>
        </div>
      </div>)
        }
    )
}
           
           
          </div>
        </div>
      </section>
      {selectedProduct !== null && <EditProductModal
        product={selectedProduct}
        show={showEditModal}
        menu={menus}
        categories={categories}
        close={()=>handleClose("edit")}/> }

{showCreateModal && <CreateProductModal menu={menus}
        categories={categories} show={showCreateModal} close={()=>handleClose("create")}/>}

    </WebsiteLayout>
  );
};

export default Admin;
