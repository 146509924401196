import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { NAIRA_SIGN } from "../configs/app";

const MySwal = withReactContent(Swal);

export const thousand = (amount) => {
  // convert number to string
  amount = String(amount);
  // return amount string with commas after every three characters
  return amount.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const displayAmount = (
  amount, pad, sign = NAIRA_SIGN,
) => {
  // return number string with commas after every three characters
  return `${thousand(Number(amount).toFixed(pad)).trim()}`;
};

export const makeTitleCase = str => {
  if (!str || str.length < 1) return "";
  return str
    .toLowerCase()
    .split(" ")
    .map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
}



export const padNumber = (amount, maxLength = 2) => {
  const num = String(amount);
  if (num.length >= maxLength) {
    return num;
  }
  // add zero to number
  amount = `0${amount.toString().trim()}`;
  if (amount.length < maxLength) {
    return padNumber(amount, maxLength);
  }
  // return amount
  return amount;
};


export const showAlert = ({ title, msg, icon, btnText }) =>
  MySwal.fire({
    title,
    text: msg,
    icon,
    confirmButtonText: btnText,
  });


  export const queryError = () => showAlert({
    title: "Error!",
    msg: "An internal error has occurred",
    icon: "error",
    btnText: "Close",
  })