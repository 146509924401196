import React from "react";
import WebsiteLayout from "../layouts/WebsiteLayout";
import { InstagramEmbed } from "react-social-media-embed";
import Banner from "../components/Banner";
let banner = require("../assets/images/gallery.jpeg");

const Gallery = () => {
  return (
    <WebsiteLayout>
      <Banner title="Gallery" link="About" bannerImage={banner} />
      <section className="sb-features sb-p-0-30">
        <div className="container">
          <div className="row">
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
                zIndex: 999,
              }}
            >
              <InstagramEmbed
                url="https://www.instagram.com/reel/Ct9IbecIRPW/?utm_source=ig_web_copy_link"
                width={328}
                captioned
              />
              <InstagramEmbed
                url="https://www.instagram.com/reel/C5dyZvaItok/?utm_source=ig_web_copy_link"
                width={328}
                captioned
              />
              <InstagramEmbed
                url="https://www.instagram.com/reel/Cv65I1ioeGx/?utm_source=ig_web_copy_link"
                width={328}
                captioned
              />
              <InstagramEmbed
                url="https://www.instagram.com/reel/C8reA-Xow2x/?utm_source=ig_web_copy_link"
                width={328}
                captioned
              />
              <InstagramEmbed
                url="https://www.instagram.com/reel/C8B8xo3IGrP/?utm_source=ig_web_copy_link"
                width={328}
                captioned
              />
              <InstagramEmbed
                url="https://www.instagram.com/reel/CvaAaw2Ixa3/?utm_source=ig_web_copy_link"
                width={328}
                captioned
              />
             
            </div>
          </div>
        </div>
      </section>
    </WebsiteLayout>
  );
};

export default Gallery;
