import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { cookieRemove } from '../utils/cookie';

function Logout() {
const history = useNavigate();
  const handleLogout = async () => {
    await cookieRemove('token');
    history('/login')
  }
  useEffect(() => {
    handleLogout();
  }, [])
  
  return (
    <div>Logout</div>
  )
}

export default Logout