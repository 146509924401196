import React from 'react'
import Footer from '../components/Footer'
import Header from '../components/Header'

const WebsiteLayout = ({children, isAdmin = false, createNewProduct = null}) => {
  return (
    <>
    <Header isAdmin={isAdmin} createNewProduct={createNewProduct}/>
    <div id="sb-dynamic-content" className="sb-transition-fade">
        {children}
        <Footer/>
    </div>

    </>
  )
}

export default WebsiteLayout