import React from 'react'
import AboutPageSection from '../components/AboutPageSection'
import AboutSection from '../components/AboutSection'
import BenefitsSection from '../components/BenefitsSection'
import DeliverySection from '../components/DeliverySection'
import HomeBanner from '../components/HomeBanner'
import ReviewsSection from '../components/ReviewsSection'
import WebsiteLayout from '../layouts/WebsiteLayout'
import InstagramGrid from '../components/InstagramGrid'

const Home = () => {
  return (
    <WebsiteLayout>
       <HomeBanner/>
       <AboutPageSection/>
       <InstagramGrid/>
       <BenefitsSection/>
       <ReviewsSection/>
       <DeliverySection/>
    </WebsiteLayout>
  )
}

export default Home