import React from 'react'
import { Link } from 'react-router-dom'
const skyGuest = require('../assets/images/skyguest.png')
const HomeBanner = () => {
  return (
   <section className="sb-banner"
  //  style={{background:`url(${skyGuest})`}}
   >
  <div className="sb-bg-1">
    <div />
  </div>
  <div className="container">
    <div className="row">
      <div className="col-lg-6">
        {/* main title */}
        <div className="sb-main-title-frame">
          <div className="sb-main-title">
            <span className="sb-suptitle sb-mb-30">Exceptional Dining Experience Awaits</span>
            <h1 className="sb-mb-30">Discover the <span>Ultimate</span> Sky High Experience </h1>
            <p className="sb-text sb-text-lg sb-mb-30">Elevate Your Dining Experience at Sky Bar. Choose from a variety of seating options, including the terrace area, VIP room, exclusive comfy cushioned lounge, and bar area.</p>
            {/* button */}
            <Link to="/menu" className="sb-btn">
              <span className="sb-icon">
                <img src="img/ui/icons/menu.svg" alt="icon" />
              </span>
              <span style={{fontSize:22}}>Our menu</span>
            </Link>
            {/* button end */}
            {/* button */}
            <Link to="/about" className="sb-btn sb-btn-2 sb-btn-gray">
              <span className="sb-icon">
                <img src="img/ui/icons/arrow.svg" alt="icon" />
              </span>
              <span style={{fontSize:22}}>About us</span>
            </Link>
            {/* button end */}
          </div>
        </div>
        {/* main title end */}
      </div>
      <div className="col-lg-6">
        <div className="sb-ilustration-fix">
          <div className="sb-illustration-1-2">
            <img src="img/illustrations/skybarfood.png" alt="food" className="sb-food-1" style={{width:"90%", top:"unset", left:"unset", right:0}} />
            {/* <img src="img/illustrations/skyfood2.png" alt="food" className="sb-food-2" />
            <img src="img/illustrations/skydrink.png" alt="food" className="sb-food-3" /> */}
            <div className="sb-illu-dialog-1"><span>😋</span></div>
            <div className="sb-illu-dialog-2"><span>🥰</span></div>
            <div className="sb-cirkle-1" />
            <div className="sb-cirkle-2" />
            <div className="sb-cirkle-3" />
            <div className="sb-cirkle-4" />
            <div className="sb-cirkle-5" />
            <img src="img/illustrations/3.svg" alt="phones" className="sb-pik-1" />
            <img src="img/illustrations/1.svg" alt="phones" className="sb-pik-2" />
            <img src="img/illustrations/2.svg" alt="phones" className="sb-pik-3" />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

  )
}

export default HomeBanner