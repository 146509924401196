import React from "react";
import { Link } from "react-router-dom";
import ReactOwlCarousel from "react-owl-carousel";
import { makeTitleCase } from "../utils/helpers";
let banner = require('../assets/images/banner.jpg')
const Banner = ({title, link, bannerImage = banner}) => {
  return (

    <section className="sb-banner sb-banner-xs sb-banner-color" style={{
      backgroundImage:`url(${bannerImage})`,
      backgroundPosition:"center",
      backgroundSize:"cover",
      backgroundRepeat:"no-repeat",
      height:400
    }}>
       
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
          {/* <ReactOwlCarousel className=""  items={1} dots={true} loop autoplay autoplayHoverPause> */}
            {/* main title */}
            <div className="sb-main-title-frame" >
              <div className="sb-main-title">
                <h1 className="sb-h2 mx-3" style={{color:"#ffffff"}}>{makeTitleCase(title)}</h1>
                <ul className="sb-breadcrumbs" style={{color:"#fff"}}>
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <a href="#">{makeTitleCase(link)}</a>
                  </li>
                </ul>
              </div>
            </div>
            {/* main title end */}
            {/* </ReactOwlCarousel> */}
          </div>
        </div>
      </div>
     
    </section>
  );
};

export default Banner;
