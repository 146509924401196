import React from 'react'
import { Link } from 'react-router-dom'

const ReviewsSection = () => {
  return (
   <section className="sb-reviews sb-p-0-90">
  <div className="container">
    <div className="sb-group-title sb-mb-30">
      <div className="sb-left sb-mb-30">
        <h2 className="sb-mb-30">Reviews <span>about</span> us</h2>
        <p className="sb-text">Here's what people have to say about us.</p>
      </div>
      <div className="sb-right sb-mb-30">
        {/* slider navigation */}
        <div className="sb-slider-nav">
          <div className="sb-prev-btn sb-reviews-prev"><i className="fas fa-arrow-left" /></div>
          <div className="sb-next-btn sb-reviews-next"><i className="fas fa-arrow-right" /></div>
        </div>
        {/* slider navigation end */}
        {/* button */}
        <Link to="/menu" className="sb-btn">
          <span className="sb-icon">
            <img src="img/ui/icons/dialog.svg" alt="icon" />
          </span>
          <span>See Menu</span>
        </Link>
        {/* button end */}
      </div>
    </div>
    <div className="swiper-container sb-reviews-slider">
      <div className="swiper-wrapper">
        <div className="swiper-slide">
          <div className="sb-review-card">
            <div className="sb-review-header sb-mb-15">
              {/* <h4 className="sb-mb-15">Very tasty</h4> */}
              <ul className="sb-stars">
                <li><i className="fas fa-star" /></li>
                <li><i className="fas fa-star" /></li>
                <li><i className="fas fa-star" /></li>
                <li><i className="fas fa-star" /></li>
                <li><i className="fas fa-star" /></li>
              </ul>
            </div>
            <p className="sb-text sb-mb-15">
            This place is great! Atmosphere is chill, cool music, delicious dishes, beautiful presentation, wide wine list. And for the staffs, you can tell making the customers happy is their main priority. I recommend to everyone number one rooftop bar in port harcourt!!.
            </p>
            <div className="sb-author-frame">
              <div className="sb-avatar-frame">
                <img src="https://lh3.googleusercontent.com/a-/AD_cMMRqfrtnwttrwLRHr5FvXxRlqYfU8ds4Kp95UHk=w120-h120-p-rp-mo-br100" alt="Guest" />
              </div>
              <h4>Clara Agu</h4>
            </div>
          </div>
        </div>
        <div className="swiper-slide">
          <div className="sb-review-card">
            <div className="sb-review-header sb-mb-15">
              {/* <h4 className="sb-mb-15">I have lunch here every day</h4> */}
              <ul className="sb-stars">
                <li><i className="fas fa-star" /></li>
                <li><i className="fas fa-star" /></li>
                <li><i className="fas fa-star" /></li>
                <li><i className="fas fa-star" /></li>
                <li><i className="fas fa-star" /></li>
              </ul>
            </div>
            <p className="sb-text sb-mb-15">Never been to a beautiful ND comfortable place as Skybar 🥂. Everything there is so good ,my next return is with my friends ,sure they will love it too. 😌🥂keep up your good work</p>
            <div className="sb-author-frame">
              <div className="sb-avatar-frame">
                <img src="https://lh3.googleusercontent.com/a-/AD_cMMRgvU8VDmWd6EgD9l8_JO1_iTEWgfdhQy6E0xUn=w120-h120-p-rp-mo-br100" alt="Guest" />
              </div>
              <h4>Queen Wakama</h4>
            </div>
          </div>
        </div>
        <div className="swiper-slide">
          <div className="sb-review-card">
            <div className="sb-review-header sb-mb-15">
              {/* <h4 className="sb-mb-15">Great service</h4> */}
              <ul className="sb-stars">
                <li><i className="fas fa-star" /></li>
                <li><i className="fas fa-star" /></li>
                <li><i className="fas fa-star" /></li>
                <li><i className="fas fa-star" /></li>
                <li className="sb-empty"><i className="fas fa-star" /></li>
              </ul>
            </div>
            <p className="sb-text sb-mb-15">Nice ambience, the redefined skybar is ok, more space outside now. Better than the old one. My only nag here is that their drinks are expensive, especially the whisky. More expensive than similar lounges. But overall it’s my perfect hangout spot 👍🏽</p>
            <div className="sb-author-frame">
              <div className="sb-avatar-frame">
                <img src="https://lh3.googleusercontent.com/a-/AD_cMMR59hSQ_7BFgHeVJ4nhRFsbxL_1x1T3LXrYQYhBWA=w120-h120-p-rp-mo-ba3-br100" alt="Guest" />
              </div>
              <h4>Otonye Dia</h4>
            </div>
          </div>
        </div>
        <div className="swiper-slide">
          <div className="sb-review-card">
            <div className="sb-review-header sb-mb-15">
              {/* <h4 className="sb-mb-15">Starbelly is amazing!</h4> */}
              <ul className="sb-stars">
                <li><i className="fas fa-star" /></li>
                <li><i className="fas fa-star" /></li>
                <li><i className="fas fa-star" /></li>
                <li><i className="fas fa-star" /></li>
                <li><i className="fas fa-star" /></li>
              </ul>
            </div>
            <p className="sb-text sb-mb-15">First off the ambience is 🔥I just love how the natural air flows at the terrace. I must say without any exaggeration that Skybar has the best services in port harcourt</p>
            <div className="sb-author-frame">
              <div className="sb-avatar-frame">
                <img src="https://lh3.googleusercontent.com/a-/AD_cMMS-2zdqHE2x2XBTLvHnUlikPkk96mUMIrJPW_zi=w120-h120-p-rp-mo-br100" alt="Guest" />
              </div>
              <h4>Achiever Kio</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

  )
}

export default ReviewsSection