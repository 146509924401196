import React from 'react'
import { NAIRA_SIGN } from '../configs/app';
import { displayAmount, makeTitleCase, } from "../utils/helpers";
import useWindowDimensions from './useWindowDimensions';


const SingleProduct = ({prod}) => {
  let amount =  prod.price?<><sub>{NAIRA_SIGN}</sub>{displayAmount(prod.price)}</>:""
  let {width} = useWindowDimensions()
  let isMobile = width <= 767 ? true:false;
  let isDesktop = width > 767 ? true: false;
  let type = prod?.menuid === "63bc24c8149f483391e80424"?"drinks": prod?.menuid === "63be02bea6390febcc40e2bd"?"smoke":prod?.menuid === "63bc2499149f483391e80420"?"food":prod?.menuid === "63be02e9a6390febcc40e2bf"?"happy-hours":""
  return (
    <div className="sb-grid-item sb-item-25 main-dishes">
                <a
                  data-fancybox="menu"
                  data-no-swup
                  href={prod.image?prod.image:require(`../assets/images/meal-placeholder.png`)}
                  className="sb-menu-item sb-mb-30"
                >
                  {/* <div className="sb-cover-frame"> */}
                    {/* <img src="img/menu/3.jpg" alt="product" /> */}
                    <img src={prod.image?prod.image:require(`../assets/images/meal-placeholder.png`)} alt={prod?.name} style={{width:"100%",height:isMobile?type==="drinks" || type==="smoke"?170:100:isDesktop?type==="drinks" || type==="smoke"?320:170:140 }} className=""/>
                    {/* <div className="sb-badge sb-hot">
                      <i className="fas fa-pepper-hot" /> Hot
                    </div> */}
                  {/* </div> */}
                  <div className="sb-card-tp">
                    <h4 className="sb-card-titleg">{makeTitleCase(prod.name)}</h4>
                    <div className="sb-price">
                     {amount}
                    </div>
                  </div>
                  <div className="sb-description">
                    <p className="sb-text sb-mb-15">
                      <span>{prod.description}</span>
                    </p>
                  </div>
                </a>
              </div>
  )
}

export default SingleProduct