import React from 'react'

const PromoVideo = () => {
  return (
    <section className="sb-video">
  <div className="container">
    <div className="row align-items-center">
      <div className="col-lg-6">
        <div className="sb-mb-90">
          <span className="sb-suptitle sb-mb-15">Promo video</span>
          <h2 className="sb-mb-30">
            An Exceptional Dining Experience Awaits You
          </h2>
          <p className="sb-text sb-mb-30">
          Immerse yourself in the epitome of fine dining at Sky Bar, where every detail is meticulously curated to create an extraordinary experience. From the exquisite cuisine to the luxurious ambiance, our dedication to perfection shines through in every aspect.
            </p>
          {/* button */}
          <a data-fancybox data-no-swup href="https://www.youtube.com/embed/FL14dBTamlo" className="sb-btn">
            <span className="sb-icon">
              <img src="img/ui/icons/play.svg" alt="icon" />
            </span>
            <span>Play video</span>
          </a>
          {/* button end */}
        </div>
      </div>
      <div className="col-lg-6 align-self-center">
        <div className="sb-illustration-7 sb-mb-90">
          <div className="sb-interior-frame">
            <img src="img/illustrations/28.jpg" alt="interior" className="sb-interior" />
            <a data-fancybox data-no-swup href="https://www.youtube.com/embed/FL14dBTamlo" className="sb-video-play"><i className="fas fa-play" /></a>
          </div>
          <div className="sb-cirkle-1" />
          <div className="sb-cirkle-2" />
          <div className="sb-cirkle-3" />
          <div className="sb-cirkle-4" />
        </div>
      </div>
    </div>
  </div>
</section>

  )
}

export default PromoVideo

// {
//     _id:"638a9a3cf1db7c6a6a01d8hb",
//     uid:"ddfecb84-2298-5467-b15b-c2c9ca90b149",
//     name:"Free Pizza Promo",
//     slug:"free-promo-pizza",
//     description:"",
//     product_price:0,
//     sale_price:0,
//     available: true,
//     image_url:"",
//     category:[
//         "all-products"
//     ],
//     category_ids:[
//         "631b1724dbc3f010460c79ef"
//     ],
//     variable:false,
//     stock_count:100,
//     status:"published",
//     tags:[],
//     cross_sell:[],
//     varieties:null,
//     state:"631758ba9032d43a553a0a23",
//     is_promo:false,
//     created:"",
//     updated:""
// }