import React from 'react'
import { InstagramEmbed } from "react-social-media-embed";
import { Link } from 'react-router-dom';

const InstagramGrid = () => {
  return (
    <section className="sb-features sb-p-0-30">
    <div className="container">
      <div className="row">
      <div style={{ display: "flex", flexWrap:"wrap", justifyContent: "space-between", zIndex:999 }}>
        <InstagramEmbed
          url="https://www.instagram.com/reel/Ct9IbecIRPW/?utm_source=ig_web_copy_link"
          width={328}
        />
         <InstagramEmbed
          url="https://www.instagram.com/reel/CvaAaw2Ixa3/?utm_source=ig_web_copy_link"
          width={328}
        />
        <InstagramEmbed
          url="https://www.instagram.com/reel/C5dyZvaItok/?utm_source=ig_web_copy_link"
          width={328}
        />
       
      </div>
      <div className="w-100" style={{ display: "flex", flexWrap:"wrap", justifyContent: "center", zIndex:999 }}>
      <Link to="/gallery" className="sb-btn">
      <span class="sb-icon"><img src="img/ui/icons/cart.svg" alt="icon"/></span>
          <span>See More</span>
        </Link>
      </div>
      </div>
    </div>
  </section>
  )
}

export default InstagramGrid
