import React, {useState, useEffect} from "react";
import { Link, useNavigate } from "react-router-dom";
import Banner from "../components/Banner";
import WebsiteLayout from "../layouts/WebsiteLayout";
import authData from "../authData.json";
import { showAlert } from "../utils/helpers";
import { cookieGet, cookieStore } from "../utils/cookie";

const Login = () => {

    let history = useNavigate();
    const [passcode, setPasscode] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const handleLogin =async (e) => {
      e.preventDefault();
      setIsLoading(true)
      let authVal = authData.find(val=>val.passcode === passcode);
      setIsLoading(false)
      if(!authVal) return showAlert({
          title: "Oops!",
          msg: "Invalid Passcode",
          icon: "error",
          btnText: "Close",
        })
        await cookieStore("token", JSON.stringify(authVal))
        history("/admin")
    };

    const checkAuth = async () => {
        let token = await cookieGet("token");
        if(!token)return;
        history("/admin")
    }

useEffect(() => {
  checkAuth()
}, [])


  return (
    <WebsiteLayout>
      <Banner title="Admin Login" link="login" />

      <section className="sb-banner sb-banner-color">
        <div className="container">
          <div className="row align-items-top">
            <div className="col-lg-7">
              {/* main title */}
              <div className="sb-main-title-frame" style={{alignItems:"top"}}>
                <div className="sb-main-title">
                  <span className="sb-suptitle sb-mb-30">Administrator</span>
                  <h1 className="sb-mb-30">
                    Login
                  </h1>
                  <p className="sb-text sb-text-lg sb-mb-30">
                    Administrators can menu and prices
                  </p>
                  <ul className="sb-breadcrumbs">
                    <li>
                      <Link to="/">Go to Menu</Link>
                    </li>
                  </ul>
                </div>
              </div>
              {/* main title end */}
            </div>
            <div className="col-lg-5">
              <div className="sb-contact-form-frame">
                <div className="sb-illustration-9">
                  <div className="sb-cirkle-1" />
                  <div className="sb-cirkle-2" />
                  <div className="sb-cirkle-3" />
                </div>
                <div className="sb-form-content">
                  <div className="sb-main-content">
                    <h3 className="sb-mb-30">Login with Passcode</h3>
                    <form onSubmit={handleLogin}>
                      <div className="sb-group-input">
                        <input type="text" name="name" required onChange={(e)=>setPasscode(e.target.value)} />
                        <span className="sb-bar" />
                        <label>Passcode</label>
                      </div>

                      {/* button */}
                      <button type="submit" className="sb-btn">
                        <span className="sb-icon">
                          <img src="img/ui/icons/arrow.svg" alt="icon" />
                        </span>
                        <span>{isLoading?"Loading...":"Login"}</span>
                      </button>
                      {/* button end */}
                    </form>
                  </div>
                  <div className="sb-success-result">
                    <img
                      src="img/ui/success.jpg"
                      alt="success"
                      className="sb-mb-15"
                    />
                    <div className="sb-success-title sb-mb-15">Success!</div>
                    <p className="sb-text sb-mb-15">
                      Your message has been sent <br />
                      successfully
                    </p>
                    {/* button */}
                    <a href="home-1.html" className="sb-btn sb-btn-2">
                      <span className="sb-icon">
                        <img src="img/ui/icons/arrow-2.svg" alt="icon" />
                      </span>
                      <span>Back to home</span>
                    </a>
                    {/* button end */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </WebsiteLayout>
  );
};

export default Login;
