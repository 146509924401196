import React, { useState } from "react";
import { Link } from "react-router-dom";

const Header = ({isAdmin, createNewProduct}) => {
    const [openMenu, setOpenMenu] = useState(false)
  return (
    <div className="sb-top-bar-frame" style={{zIndex:3}}>
      <div className="sb-top-bar-bg" />
      <div className="container">
        <div className="sb-top-bar">
          <Link to="/" className="sb-logo-frame">
            {/* logo img */}
            <img className="img-responsive" style={{width:"70%"}} src={require("../assets/images/logo.png")} alt="Eden Logo" />
          </Link>
          {/* menu */}
          <div className="sb-right-side">
            <nav id="sb-dynamic-menu" className="sb-menu-transition">
              <ul className="sb-navigation">
              <li><Link to="/">Home</Link></li>
              <li><Link to="/menu">Menu</Link></li>
              <li><Link to="/about">About</Link></li>
              </ul>
            </nav>
            <div className="sb-buttons-frame">
              {/* button */}
              <div className="sb-btn sb-btn-2 sb-btn-gray sb-btn-icon sb-m-0 sb-btn-cart">
                {isAdmin
                ?<>
                <button className="btn btn-primary btn-sm" onClick={createNewProduct}>
                  <i className="fa fa-plus"></i>{" "}
                   New Product
                   </button>
                </>
                :<><span className="sb-icon">
                <img src="img/ui/icons/cart.svg" alt="icon" />
              </span>
              <i className="sb-cart-number">0</i></>
                }
                
              </div>
              {/* button end */}
              {/* menu btn */}
              {/* <div className="sb-menu-btn">
                <span />
              </div> */}
              {/* info btn */}
              <div onClick={()=>setOpenMenu(!openMenu)} className={`sb-info-btn ${openMenu?"sb-active":""}`}>
                <span />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* info bar */}
      <div className={`sb-info-bar ${openMenu?"sb-active":""}`}>
        <div className="sb-infobar-content">
          <div className="sb-ib-title-frame sb-mb-30">
            <h4>Contact</h4>
            <i className="fas fa-arrow-down" />
          </div>
          <ul className="sb-list sb-mb-30">
            <li>
              <b>Address:</b>
              <span>3rd Floor Genesis Center, GRA Port Harcourt</span>
            </li>
            <li>
              <b>Working hours:</b>
              <div>
              <span>Mon - Thur 04:00PM - 01:00AM</span><br/>
              <span>Fri - Sun 02:00PM - 01:00AM</span>
              </div>
            </li>
            <li>
              <b>Phone:</b>
              <span>+(234) 908-761-0221</span>
            </li>
            <li>
              <b>Email:</b>
              <span>head.skybar@genesisgroupng.com</span>
            </li>
          </ul>
          {isAdmin && <Link to="/logout" className="btn btn-block btn-danger btn-lg">
                <i className="fa fa-logout"></i>
                Logout
              </Link>}
        </div>
        <div className="sb-info-bar-footer">
        
        </div>
      </div>
      {/* info bar end */}
    </div>
  );
};

export default Header;
